import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { dateFilter } from "./core/filters/date.filter";

import "video.js/dist/video-js.css";
import "../src/assets/style/variables.scss";

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { initMiceInfo } from "../src/core/miceInfo";

import {
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Button,
  Radio,
  RadioGroup,
  Cascader,
  Message,
  Pagination,
} from "element-ui";

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

Vue.filter("date", dateFilter);

Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cascader);
Vue.use(Pagination);

Vue.prototype.$message = Message;

// 获取展会信息
initMiceInfo().then(() => {
  const vm = new Vue({
    render: (h) => h(App),
    router,
  });
  console.log(vm);
  vm.$mount("#app");
});
