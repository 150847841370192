<template>
  <div class="video-li" :key="index">
    <video
      :id="`my-video-${index}`"
      class="video-js"
      controls
      preload="auto"
      playsinline
      webkit-playsinline
    >
      <source :src="video.url" type="video/mp4" />
    </video>
    <div v-show="!isPaly" class="img-box">
      <img src="../../assets/img/play_icon.png" alt="" />
    </div>
    <div class="foot" v-if="type === 'news'">
      <p>{{ video.name }}</p>
      <span>{{ (video.create_time * 1000) | date("YYYY年MM月DD日") }}</span>
    </div>
  </div>
</template>
<script>
import videoJS from "video.js";
export default {
  name: "video-item",
  props: {
    video: Object,
    index: Number,
    type: String,
  },
  data() {
    return {
      isPaly: false,
    };
  },
  mounted() {
    this.loadVideo();
  },
  methods: {
    loadVideo() {
      let _this = this;
      let options = {};
      videoJS(`my-video-${this.index}`, options, function onReady() {
        // 监听 点击播放按钮
        this.on("play", function () {
          _this.isPaly = true;
        });
        this.on("pause", function () {
          _this.isPaly = false;
        });
        this.on("ended", function () {
          _this.isPaly = false;
        });
      });
    },
  },
};
</script>
<style lang="scss">
.video-list {
  .video-li {
    width: 266px;
    // height: 150px;
    position: relative;
    .img-box {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      z-index: 1;
      img {
        width: 49px;
        pointer-events: none;
      }
    }
    .foot {
      font-size: 16px;
      margin-top: 20px;
      p {
        margin-bottom: 10px;
      }
      span {
        color: #808080;
      }
    }
  }
}
</style>