<template>
  <div id="banner">
    <swiper :options="swiperOption">
      <swiper-slide v-for="item in list" :key="item">
        <img :src="item" style="width: 100vw" />
      </swiper-slide>
      <div class="s-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev">
        <img
          src="../../assets/img/arr_right_4.png"
          style="transform: rotate(180deg)"
        />
      </div>
      <div class="swiper-button-next" slot="button-next">
        <img src="../../assets/img/arr_right_4.png" />
      </div>
    </swiper>
  </div>
</template>
<script>
import { getMiceInfo } from "../../core/miceInfo";
export default {
  name: "page-banner",
  data() {
    return {
      list: [],
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".s-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      },
    };
  },
  async created() {
    const data = await getMiceInfo();
    this.list = JSON.parse(data.banner_image ?? "[]") || [];
  },
};
</script>
<style lang="scss" scoped>
#banner {
  background: #e8e8e8;
  .swiper-container {
    // --swiper-theme-color: #fff; /* 设置Swiper风格 */
    --swiper-navigation-color: #fff; /* 单独设置按钮颜色 */
    /deep/.swiper-pagination-bullet {
      width: 1.4vw;
      height: 0.24vw;
      background: #fff;
      opacity: 1;
      border-radius: 0;
    }
    /deep/.swiper-pagination-bullet-active {
      background: #016ac8;
    }
    /deep/.swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
      content: "";
    }
    /deep/.swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after {
      content: "";
    }
    .s-pagination {
      position: absolute;
      text-align: center;
      transition: 300ms opacity;
      transform: translate3d(0, 0, 0);
      z-index: 10;
    }
  }
}
</style>