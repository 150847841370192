import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import HomePage from "@/pages/index";
import Layout from "@/pages/layout";

export default new Router({
  routes: [
    { path: "/", component: HomePage, name: "index", meta: "首页" },
    {
      path: "/about",
      component: Layout,
      name: "about",
      meta: "关于展会",
      children: [
        {
          path: "/about/overview",
          component: () => import("@/pages/about/overview"),
          name: "overview",
          meta: "展会概况",
        },
        {
          path: "/about/section",
          component: () => import("@/pages/about/section"),
          name: "section",
          meta: "展览板块",
        },
        {
          path: "/about/area-distribution",
          component: () => import("@/pages/about/area-distribution"),
          name: "area-distribution",
          meta: "展区分布",
        },
        {
          path: "/about/org-unit",
          component: () => import("@/pages/about/org-unit"),
          name: "org-unit",
          meta: "组织单位",
        },
        {
          path: "/about/download-center",
          component: () => import("@/pages/about/download-center"),
          name: "download-center",
          meta: "下载中心",
        },
      ],
    },
    {
      path: "/service",
      component: Layout,
      name: "service",
      meta: "展商服务",
      children: [
        {
          path: "/service/why",
          component: () => import("@/pages/service/why"),
          name: "why",
          meta: "为何参展",
        },
        {
          path: "/service/booking",
          component: () => import("@/pages/service/booth-booking"),
          name: "booth-booking",
          meta: "展位预定",
        },
        {
          path: "/service/sponsorship",
          component: () => import("@/pages/service/sponsorship-promotion"),
          name: "sponsorship-promotion",
          meta: "赞助推广",
        },

        {
          path: "/service/manual",
          component: () => import("@/pages/service/exhibitor-manual"),
          name: "exhibitor-manual",
          meta: "展商手册",
        },
        {
          path: "/service/professional",
          component: () => import("@/pages/service/professional-audience"),
          name: "professional-audience",
          meta: "专业观众",
        },
      ],
    },
    {
      path: "/audience",
      component: Layout,
      name: "audience-service",
      meta: "观众服务",
      children: [
        {
          path: "/audience/visit-registration",
          component: () =>
            import("@/pages/audience-service/visit-registration"),
          name: "visit-registration",
          meta: "参观报名",
        },
        {
          path: "/audience/visit-manual",
          component: () => import("@/pages/audience-service/visit-manual"),
          name: "visit-manual",
          meta: "参观手册",
        },
        {
          path: "/audience/guide",
          component: () => import("@/pages/audience-service/traffic-guide"),
          name: "traffic-guide",
          meta: "交通指南",
        },
        {
          path: "/audience/hotel",
          component: () => import("@/pages/audience-service/hotel-info"),
          name: "hotel-info",
          meta: "酒店信息",
        },
        // {
        //   path: "/audience/epidemic",
        //   component: () => import("@/pages/audience-service/epidemic-control"),
        //   name: "epidemic-control",
        //   meta: "疫情防控",
        // },
      ],
    },
    {
      path: "/activities",
      component: Layout,
      name: "activities",
      meta: "同期活动",
      children: [
        {
          path: "/activities/conference",
          component: () => import("@/pages/activities/conference-forum"),
          name: "conference-forum",
          meta: "会议论坛",
        },
        {
          path: "/activities/sup-activitie",
          component: () => import("@/pages/activities/sup-activitie"),
          name: "sup-activitie",
          meta: "配套活动",
        },
      ],
    },
    {
      path: "",
      component: Layout,
      name: "",
      meta: "同期展会",
      children: [
        {
          path: "",
          component: Layout,
          name: "",
          meta: "水博会",
          href: "https://worldwice.com/#/home",
        },
        {
          path: "",
          component: Layout,
          name: "",
          meta: "光储展",
          href: "http://cneexpo.cn/#/home",
        },
      ],
    },
    {
      path: "/media",
      component: Layout,
      name: "media",
      meta: "媒体中心",
      children: [
        {
          path: "/media/news",
          component: () => import("@/pages/media-center/news"),
          name: "news",
          meta: "新闻中心",
        },
        {
          path: "/media/review",
          name: "review",
          meta: "精彩回顾",
          component: () => import("@/pages/media-center/layout"),
          children: [
            {
              path: "/media/review/first",
              component: () => import("@/pages/media-center/review"),
              name: "news",
              meta: "第一届",
            },
            {
              path: "/media/review/second",
              component: () => import("@/pages/media-center/review-second"),
              name: "news",
              meta: "第二届",
            },
            {
              path: "/media/review/third",
              component: () => import("@/pages/media-center/review-third"),
              name: "news",
              meta: "第三届",
            },
          ],
        },
        {
          path: "/media/associated",
          component: () => import("@/pages/media-center/associated"),
          name: "associated",
          meta: "合作媒体",
        },
      ],
    },
    {
      path: "/contact",
      component: () => import("@/pages/contact"),
      name: "contact",
      meta: "联系我们",
    },
  ],
});
