<template>
  <div id="countDown">
    <div class="circle">{{ day }}</div>
    <span>天</span>
    <div class="circle">{{ hour }}</div>
    <span>时</span>
    <div class="circle">{{ min }}</div>
    <span>分</span>
    <div class="circle">{{ seconds }}</div>
    <span>秒</span>
  </div>
</template>
<script>
import { formateTimeStamp } from "../core/util";
export default {
  name: "count-down",
  data() {
    return {
      timestamp: 1720022400,
      day: "00",
      hour: "00",
      min: "00",
      seconds: "00",
      timer: null,
    };
  },
  created() {
    this.timer = setInterval(() => {
      this.setCountDown();
    }, 1000);
  },
  methods: {
    setCountDown() {
      const nowTimestamp = Date.parse(new Date()) / 1000;
      const { day, hour, min, seconds } = formateTimeStamp(
        this.timestamp - nowTimestamp
      );
      this.day = day;
      this.hour = hour;
      this.min = min;
      this.seconds = seconds;
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
#countDown {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: 600;
  .circle {
    font-size: 22px;
    color: #0054e1;
    background: #fff;
    position: relative;
    z-index: 1;
    margin: 0 5px;
    padding: 0 10px;
    border-radius: 15px;
    text-align: center;
  }
  span {
    display: block;
    margin: 0 5px;
  }
}
</style>