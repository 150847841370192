<template>
  <div>
    <img src="../assets/img/banner_3.png" style="width: 100vw" />
    <Breadcrumb />
    <ComTitle />
    <router-view></router-view>
  </div>
</template>
<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import ComTitle from "@/components/about/com-title.vue";

export default {
  name: "about-index",
  components: { Breadcrumb, ComTitle },
};
</script>
<style lang="scss">
</style>