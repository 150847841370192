<template>
  <div class="breadcrumb">
    <img src="../assets/img/home_icon.png" alt="" />
    首页 — {{ breadcrumb }}
  </div>
</template>

<script>
export default {
  name: "c-breadcrumb",
  data() {
    return {
      breadcrumb: "",
    };
  },
  watch: {
    $route: function () {
      this.setBreadcrumb();
    },
  },
  created() {
    if (!this.breadcrumb) {
      this.setBreadcrumb();
    }
  },
  methods: {
    setBreadcrumb() {
      const { matched } = this.$route;
      this.breadcrumb = matched
        .map((item) => {
          return item.meta;
        })
        .join(" — ");
    },
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  height: 70px;
  border-bottom: 1px solid #b5b2b2;
  font-size: 18px;
  padding: 0 360px;
  display: flex;
  align-items: center;
  img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
}
</style>