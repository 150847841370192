<template>
  <div id="header">
    <div class="top">
      <img src="../assets/img/logo.png" alt="" />
      <div class="count-down">
        <p>距离第三届中国新能源和节能环保产业博览会开幕还有</p>
        <count-down />
      </div>
    </div>
    <div class="nav">
      <div class="nav-left">
        <div class="nav-left-row">
          <img src="../assets/img/rl.png" alt="" />
          <p>2024.7.4-6</p>
        </div>
        <div class="nav-left-row">
          <img src="../assets/img/dw.png" alt="" />
          <p>合肥滨湖国际会展中心</p>
        </div>
      </div>
      <ul class="nav-right">
        <li v-for="(item, index) in routerList" :key="index">
          <template v-if="item.children">
            <span>{{ item.meta }}</span>
            <div class="children-node">
              <div class="item">{{ item.meta }}</div>
              <template v-for="(node, key) in item.children">
                <template v-if="node.children">
                  <div
                    class="item"
                    :key="key"
                    :class="node.active ? 'active' : ''"
                  >
                    {{ node.meta }}
                    <ul class="item-sun" v-if="node.children">
                      <li
                        v-for="(n, idx) in node.children"
                        :key="idx"
                        @click="goToPath(n)"
                        :class="n.active ? 'select' : ''"
                      >
                        {{ n.meta }}
                      </li>
                    </ul>
                  </div>
                </template>
                <div
                  v-else
                  class="item"
                  :key="key"
                  :class="node.active ? 'active' : ''"
                  @click="goToPath(node)"
                >
                  {{ node.meta }}
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <p @click="goToPath(item)">{{ item.meta }}</p>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import CountDown from "./count-down.vue";
export default {
  name: "page-header",
  components: {
    CountDown,
  },
  data() {
    return {
      routerList: [],
    };
  },
  watch: {
    $route: function () {
      this.changeRoute();
    },
  },
  created() {
    this.changeRoute();
  },
  methods: {
    changeRoute() {
      this.routerList = this.$router.options.routes.map((item) => {
        return {
          ...item,
          active: this.$route.path === item.path,
          children: item.children
            ? item.children.map((c) => {
                let flag = 0;
                const children =
                  c?.children?.map((i) => {
                    if (this.$route.path === i.path) flag++;
                    return {
                      ...i,
                      active: this.$route.path === i.path,
                    };
                  }) || "";
                return {
                  ...c,
                  active: this.$route.path === c.path || flag > 0,
                  children,
                };
              })
            : "",
        };
      });
    },
    goToPath(item) {
      const { href, path } = item;
      if (href) {
        window.open(href);
        return;
      }
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="scss" scoped>
#header {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to right, #01752a, #016ac8);
    color: #fff;
    padding: 20px 360px;
    img {
      max-height: 50px;
    }
    .count-down {
      p {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 10px;
      }
    }
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    padding: 20px 366px;
    .nav-left {
      color: #666;
      .nav-left-row {
        display: flex;
        font-size: 16px;
        align-items: center;
        img {
          width: 28px;
          margin-right: 20px;
        }
      }
    }
    .item-sun {
      position: absolute;
      left: 110px;
      top: 0;
      background: #30913a;
      border-radius: 10px;
      display: none;
      li {
        color: #fff;
        line-height: 40px;
        &:hover {
          color: #30913a;
          background: #fff;
        }
        &:first-child {
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
        }
        &:last-child {
          border-end-end-radius: 9px;
          border-end-start-radius: 9px;
        }
      }
      .select {
        color: #30913a;
        background: #fff;
      }
    }
    .nav-right {
      display: flex;
      font-size: 20px;
      z-index: 2;
      li {
        text-align: center;
        width: 110px;
        height: 40px;
        position: relative;
        cursor: pointer;
        .children-node {
          font-size: 18px;
          display: none;
          margin-top: -8px;
          color: #fff;
          background: #30913a;
          border-radius: 10px;
          .item {
            padding: 8px 15px;
            padding-bottom: 5px;
            border-bottom: 1px dashed #fff;
            position: relative;
            &:last-child {
              border: unset;
              border-end-end-radius: 9px;
              border-end-start-radius: 9px;
            }
            &:hover {
              color: #30913a;
              background: #fff;
              .item-sun {
                display: block;
              }
            }
          }
          .active {
            color: #30913a;
            background: #fff;
          }
        }
        &:hover {
          .children-node {
            display: block;
          }
          span {
            display: none;
          }
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
</style>