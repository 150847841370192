<template>
  <div id="msg-scroll">
    <img src="../../assets/img/msg_icon.png" alt="" />
    <vue-seamless-scroll
      :data="listData"
      :class-option="classOption"
      class="warp"
    >
      <div
        class="scroll-warp"
        v-for="(item, index) in listData"
        :key="index"
        @click="openUrl(item.url)"
      >
        <p>{{ item.title }}</p>
        <span>{{ (item.update_time * 1000) | date("YYYY.MM.DD") }}</span>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import * as api from "../../core/api";
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  name: "msg-scroll",
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      listData: [],
      classOption: {
        limitMoveNum: 1,
        direction: 1,
        step: 0.2,
      },
    };
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
  },
  async created() {
    // 新闻中心
    const res = await api.get("/w/news/list");
    if (res && res.list) {
      const { list } = res;
      this.listData = list;
    }
  },
};
</script>
<style lang="scss" scoped>
#msg-scroll {
  display: flex;
  padding: 20px 360px;
  background: #e8e8e8;
  color: #333;
  font-size: 24px;
  img {
    width: 48px;
    height: 48px;
    margin-right: 50px;
  }
  .warp {
    height: 50px;
    overflow: hidden;
    flex: auto;
    .scroll-warp {
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      cursor: pointer;
    }
  }
}
</style>