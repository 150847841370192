<template>
  <div id="footer">
    <div class="content">
      <div class="col-box" v-for="(list, key) in routerList" :key="key">
        <p>{{ list.meta }}</p>
        <ul>
          <li
            v-for="(item, index) in list.children"
            :key="index"
            @click="goToPath(item)"
          >
            {{ item.meta }}
          </li>
        </ul>
      </div>
      <div class="col-box" style="flex: 2">
        <p>联系我们</p>
        <ul>
          <li>参展热线： <br />0551-6215 5750</li>
          <li>联系人： <br />章先生 151 5609 1544</li>
          <li>地址： <br />安徽省合肥市经开区天都路129号</li>
        </ul>
      </div>
      <div class="col-box flex">
        <div class="img-box">
          <img src="../assets/img/mp_2.png" alt="" />
          <p>抖音号</p>
        </div>
        <div class="img-box">
          <img src="../assets/img/mp_3.jpg" alt="" />
          <p>公众号</p>
        </div>
        <div class="img-box">
          <img src="../assets/img/mp_1.jpg" alt="" />
          <p>小程序</p>
        </div>
      </div>
    </div>
    <div class="beian">
      <a href="http://beian.miit.gov.cn" target="_blank">
        皖ICP备12010259号-33
      </a>
      <div class="gongan">
        <img src="../assets/img/gongan.png" />
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34019102000774"
          target="_blank"
        >
          皖公网安备 34019102000774号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-footer",
  data() {
    return {
      routerList: [],
    };
  },
  created() {
    this.routerList = this.$router.options.routes.slice(1, 7);
  },
  methods: {
    goToPath(item) {
      const { href, path } = item;
      if (href) {
        window.open(href);
        return;
      }
      this.$router.push({ path });
    },
  },
};
</script>

<style lang="scss" scoped>
#footer {
  color: #fff;
  padding: 20px 360px;
  background: #4d4d4d;
  font-size: 20px;
  .content {
    display: flex;
    justify-content: space-between;
    .col-box {
      flex: 1;
      p {
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: 600;
      }
      font-size: 16px;
    }
    .flex {
      display: flex;
    }
    .img-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 160px;
        height: 160px;
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }

    li {
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .beian {
    width: 100%;
    display: flex;
    align-items: center;
    a {
      color: #fff;
    }
    .gongan {
      margin-left: 50px;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
  }
}
</style>