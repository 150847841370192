<template>
  <div id="statistics">
    <div class="s-box" v-for="(item, index) in list" :key="index">
      <img :src="item.icon" alt="" />
      <span>{{ item.name }}</span>
      <p>
        <animated-number
          :value="item.value"
          :formatValue="formatToPrice"
          :duration="450"
        />{{ item.unit }}
      </p>
    </div>
  </div>
</template>
<script>
import AnimatedNumber from "animated-number-vue";
import { getMiceInfo } from "../core/miceInfo";

export default {
  name: "page-statistics",
  components: {
    AnimatedNumber,
  },
  data() {
    return {
      list: [],
    };
  },
  async created() {
    // 关于
    const result = await getMiceInfo();
    if (result) {
      try {
        const list = JSON.parse(result.extra_info || "");
        this.list = list.map((item) => {
          return {
            ...item,
            value: parseInt(item.num),
            unit: item.num.slice(item.num.length - 1),
          };
        });
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    formatToPrice(value) {
      return `${value.toFixed(0)}`;
    }, // 调用
  },
};
</script>
<style lang="scss" scoped>
#statistics {
  display: flex;
  justify-content: space-between;

  .s-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    line-height: 40px;
    margin: 10px 0;
    img {
      width: 70px;
      height: 70px;
      margin-bottom: 10px;
    }
    p {
      font-size: 34px;
      font-weight: 600;
    }
  }
}
</style>