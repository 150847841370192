<template>
  <div id="news-center">
    <TitleLine>
      <template v-slot:tOne> 新闻中心 </template>
      <template v-slot:tTow> News Center </template>
    </TitleLine>
    <ul class="video-list">
      <li v-for="(video, index) in videoList" :key="index">
        <VideoItem :key="index" :index="index" :video="video" />
      </li>
    </ul>
    <div class="news-box">
      <div class="article-box" @click="openUrl(firstNew.url)">
        <img src="../../assets/img/new_1.png" alt="" />
        <h3 class="list-row">{{ firstNew.title }}</h3>
        <p class="list-row">
          {{ (firstNew.update_time * 1000) | date() }}
        </p>
      </div>
      <ul class="new-list">
        <li v-for="item in list" :key="item.id" @click="openUrl(item.url)">
          <span style="margin-right: 30px">{{
            (item.update_time * 1000) | date()
          }}</span>
          <span>{{ item.title }}</span>
        </li>
      </ul>
    </div>
    <div class="btn-box">
      <button @click="goToPath">查看更多>></button>
      <button @click="goToPath">更多资讯>></button>
    </div>
  </div>
</template>
<script>
import * as api from "../../core/api";
import TitleLine from "@/components/title-line.vue";
import VideoItem from "@/components/media-center/video.vue";
export default {
  components: {
    TitleLine,
    VideoItem,
  },
  data() {
    return {
      firstNew: {},
      list: [],
      videoList: [],
    };
  },
  async created() {
    // 新闻中心
    const res = await api.get("/w/news/list", {
      page: 0,
      pageSize: 6,
    });
    if (res && res.list) {
      const { list } = res;
      this.firstNew = list[0];
      this.list = list.slice(1, list.length);
    }
    // 视频列表
    // 获取新环会视频的接口  http://www.cppeexpo.com/api/w/upload/list?typeId=7&miceId=5iDwTj4QTjH3UU9hi6JI9S
    const res1 = await api.get("/w/upload/list", {
      page: 0,
      pageSize: 4,
      typeId: "7",
    });
    if (res1 && res1.list) {
      this.videoList = res1.list;
    }
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
    goToPath() {
      this.$router.push({ path: "/media/news" });
    },
  },
};
</script>
<style lang="scss" scoped>
#news-center {
  background: #e8e8e8;
  padding: 25px 360px;
  .video-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;
    /deep/.video-js {
      width: 100%;
      height: 164px;
    }
    /deep/.vjs-big-play-button {
      display: none;
    }
  }
  .news-box {
    display: flex;
    font-size: 16px;
    color: #333;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .article-box {
      font-size: 16px;
      cursor: pointer;
      img {
        width: 520px;
        height: 295px;
        margin-bottom: 20px;
        margin-right: 100px;
      }
    }
    .list-row {
      line-height: 40px;
    }
    .new-list {
      flex: auto;
      font-size: 16px;
      li {
        border-bottom: 1px solid #b5b2b2;
        line-height: 75px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
  .btn-box {
    display: flex;
    justify-content: space-between;
    button {
      border: unset;
      background: unset;
      color: #348942;
      font-size: 24px;
    }
  }
}
</style>