<template>
  <div id="reserve-btn">
    <button @click="goToPath('/audience/visit-registration')">
      <img src="../assets/img/btn_2.png" />
      <span>观众预登记</span>
    </button>
    <button @click="goToPath('/service/booking')">
      <img src="../assets/img/btn_1.png" />
      <span>立即预定展位</span>
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    goToPath(path) {
      this.$router.push({ path });
    },
  },
};
</script>

<style lang="scss" scoped>
#reserve-btn {
  display: flex;
  justify-content: space-evenly;
  button {
    border: unset;
    background: unset;
    cursor: pointer;
    background: #0054e1;
    color: #fff;
    font-size: 14px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-width: 145px;
    min-height: 48px;
    padding: 0 5px;
    font-weight: bold;
    img {
      width: 34px;
      height: 34px;
    }
  }
}
</style>