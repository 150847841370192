<template>
  <div id="com-title">
    <h2>{{ title }}</h2>
    <div style="flex: 1">
      <ReserveBtn />
    </div>
  </div>
</template>
<script>
import ReserveBtn from "@/components/reserve-btn.vue";
export default {
  components: {
    ReserveBtn,
  },
  data() {
    return {
      title: "",
    };
  },
  watch: {
    $route: function () {
      this.setTitle();
    },
  },
  created() {
    if (!this.title) {
      this.setTitle();
    }
  },
  methods: {
    setTitle() {
      const { meta } = this.$route;
      this.title = meta;
    },
  },
};
</script>

<style lang="scss" scoped>
#com-title {
  display: flex;
  justify-content: space-between;
  padding: 25px 380px;
  h2 {
    font-size: 32px;
    flex: 1;
  }
}
</style>
