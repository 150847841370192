<template>
  <div id="c-media">
    <TitleLine>
      <template v-slot:tOne> 合作媒体 </template>
      <template v-slot:tTow> Cooperative Media </template>
    </TitleLine>
    <swiper :options="swiperOption">
      <swiper-slide v-for="(item, index) in list" :key="index">
        <div
          v-for="l in item"
          :key="l.image"
          class="coop-item"
          @click="goToUrl(l)"
        >
          <img :src="l.image" :dataset="l" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import * as api from "../../core/api";
import TitleLine from "@/components/title-line.vue";
export default {
  name: "cooperative-media",
  components: {
    TitleLine,
  },
  data() {
    return {
      list: [],
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置自动轮播
        autoplay: {
          delay: 2000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      },
    };
  },
  methods: {
    goToUrl(item) {
      if (item.url) {
        window.open(item.url);
      }
    },
  },
  async created() {
    // 媒体合作
    const res = await api.get("/w/friends/list");
    console.log(res);
    if (res) {
      const list = res.find(
        (item) => item.miceId === "5iDwTj4QTjH3UU9hi6JI9S"
      )?.list;
      const len = Math.ceil(list.length / 15);
      for (let i = 0; i < len; i++) {
        const item = list.splice(0, 15);
        this.list.push(item);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
#c-media {
  background: #e8e8e8;
  padding: 25px 360px;
  .swiper-slide {
    display: flex;
    flex-wrap: wrap;

    .coop-item {
      width: 186px;
      height: 80px;
      text-align: center;
      background: #fff;
      margin: 0 20px 30px;
      cursor: pointer;
      z-index: 9999;
      img {
        width: 186px;
        height: 80px;
      }
    }
  }
}
</style>