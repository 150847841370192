<template>
  <div id="exhibition-section">
    <TitleLine>
      <template v-slot:tOne> 展览板块 </template>
      <template v-slot:tTow> Exhibition Section </template>
    </TitleLine>
    <swiper :options="swiperOption">
      <swiper-slide v-for="(item, index) in list" :key="item.id">
        <div
          class="swiper-box"
          @mouseover="hoverBox(index)"
          @mouseout="hoverBox(index)"
        >
          <img :src="item.isHover ? item.image : item.icon" />
          <div class="line"></div>
          <div>{{ item.name }}</div>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev">
        <img
          src="../../assets/img/arr_right_1.png"
          style="transform: rotate(180deg)"
        />
      </div>
      <div class="swiper-button-next" slot="button-next">
        <img src="../../assets/img/arr_right_1.png" />
      </div>
    </swiper>
  </div>
</template>
<script>
import * as api from "../../core/api";
import TitleLine from "@/components/title-line.vue";
export default {
  name: "exhibition-section",
  components: {
    TitleLine,
  },
  data() {
    return {
      list: [],
      swiperOption: {
        slidesPerView: 5,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
      },
    };
  },
  methods: {
    hoverBox(index) {
      this.list = this.list.map((item, key) => {
        return {
          ...item,
          isHover: index === key ? !item.isHover : item.isHover,
        };
      });
    },
  },
  async created() {
    // 媒体合作
    const res = await api.get("/w/get/exhibit");
    if (res) {
      this.list = res.map((i) => {
        return { ...i, isHover: false };
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#exhibition-section {
  padding: 30px 360px;
  background: url("../../assets/img/bj_1.png");
  .swiper-box {
    font-size: 16px;
    background: #0054e1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    margin: 10px 30px;
    height: 220px;
    cursor: pointer;
    color: #fff;
    img {
      width: 66px;
    }
    .line {
      width: 28px;
      height: 2px;
      background: #fff;
      margin: 30px 0;
    }
    &:hover {
      .line {
        background: black;
      }
      background: #fff;
      color: black;
    }
  }
  /deep/.swiper-container {
    padding: 0 20px;
  }
  /deep/.swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: "";
  }
  /deep/.swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: "";
  }
}
</style>