<template>
  <div id="link">
    <TitleLine>
      <template v-slot:tOne> 友情链接 </template>
      <template v-slot:tTow> Friendship Link </template>
    </TitleLine>
    <ul>
      <li v-for="(item, index) in linkList" :key="index">
        <a :href="item.url">{{ item.name }}</a>
        <span v-if="index !== 4 && index !== linkList.length - 1"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import TitleLine from "@/components/title-line.vue";
export default {
  name: "page-link",
  data() {
    return {
      linkList: [
        {
          name: "中华人民共和国生态环境部",
          url: "http://www.mee.gov.cn/",
        },
        {
          name: "中国环境报社",
          url: "https://www.cenews.com.cn/",
        },
        {
          name: "中国环境保护产业协会",
          url: "http://www.caepi.org.cn/",
        },
        {
          name: "安徽省生态环境厅",
          url: "http://sthjt.ah.gov.cn/",
        },
        {
          name: "安徽省环境保护产业发展促进会",
          url: "http://ahepi.org/index.aspx",
        },
        {
          name: "中华环保联合会",
          url: "http://www.acef.com.cn/index.html",
        },
        {
          name: "人民环保网",
          url: "http://env.people.com.cn/",
        },
        {
          name: "安徽省循环经济研究院",
          url: "http://www.chinace.org.cn/",
        },
        {
          name: "合肥市生态环境局",
          url: "http://sthjj.hefei.gov.cn/index.html",
        },
        {
          name: "合肥市生态环境保护协会",
          url: "http://hfepa.org.cn/",
        },
      ],
    };
  },
  components: {
    TitleLine,
  },
};
</script>
<style lang="scss" scoped>
#link {
  padding: 20px 360px;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      display: flex;
      width: 17%;
      justify-content: space-between;
      align-items: center;
      margin-right: 30px;
      margin-bottom: 20px;
      font-weight: 600;
      text-align: center;
      a {
        color: #333;
        font-size: 16px;
        flex-wrap: wrap;
      }
      span {
        display: block;
        width: 3px;
        height: 17px;
        background: #016ac8;
      }
      &:nth-child(5n + 5) {
        margin-right: 0;
        width: 19%;
      }
    }
  }
}
</style>