import axios from 'axios'
import qs from 'qs';

import { apiUrl } from './config'
import { Message } from "element-ui";

axios.defaults.timeout = 10000; //设置超时时间为10s```

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(apiUrl + url, {
            params: { ...params, miceId: '5iDwTj4QTjH3UU9hi6JI9S' }
        }).then(res => {
            const { data, code } = res?.data
            if (code === 200) {
                resolve(data);
            }
            else {
                Message({
                    message: data?.msg || '提交失败！',
                    type: "error",
                });
                reject(data)
            }
        }).catch(err => {
            reject(err.data)
        })
    })
}
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + url, qs.stringify({ ...params, miceId: '5iDwTj4QTjH3UU9hi6JI9S' }))
            .then(res => {
                const { data, code } = res?.data
                if (code === 200) {
                    resolve(data);
                }
                else {
                    Message({
                        message: data?.msg || '提交失败！',
                        type: "error",
                    });
                    reject(false)
                }
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
