import { render, staticRenderFns } from "./reserve-btn.vue?vue&type=template&id=c3f28ac4&scoped=true&"
import script from "./reserve-btn.vue?vue&type=script&lang=js&"
export * from "./reserve-btn.vue?vue&type=script&lang=js&"
import style0 from "./reserve-btn.vue?vue&type=style&index=0&id=c3f28ac4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3f28ac4",
  null
  
)

export default component.exports