import * as api from "./api";

export const initMiceInfo = async () => {
  const res = await api.get("/w/mice");
  if (res) {
    sessionStorage.setItem("miceInfo", JSON.stringify(res));
  }
};

export const getMiceInfo = async () => {
  const miceInfoStr = sessionStorage.getItem("miceInfo");
  if (miceInfoStr) {
    return JSON.parse(miceInfoStr);
  }
  const res = await initMiceInfo();

  if (res) {
    sessionStorage.setItem("miceInfo", JSON.stringify(res));
    return res;
  }
  return {};
};
