<template>
  <div id="brand-exhibitor">
    <TitleLine>
      <template v-slot:tOne> 品牌展商 </template>
      <template v-slot:tTow> Brand Exhibitor </template>
    </TitleLine>
    <swiper :options="swiperOption">
      <swiper-slide v-for="(item, index) in list" :key="index">
        <div v-for="(l, idex) in item" :key="idex" class="coop-item">
          <img :src="l.logo" />
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev">
        <img
          src="../../assets/img/arr_right_1.png"
          style="transform: rotate(180deg)"
        />
      </div>
      <div class="swiper-button-next" slot="button-next">
        <img src="../../assets/img/arr_right_1.png" />
      </div>
    </swiper>
  </div>
</template>
<script>
import * as api from "../../core/api";
import TitleLine from "@/components/title-line.vue";
export default {
  name: "brand-exhibitor",
  components: {
    TitleLine,
  },
  data() {
    return {
      list: [],
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 500000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      },
    };
  },
  async created() {
    //品牌展商
    // const res = await api.get("/w/directory/list", { all: true });
    // 媒体合作
    const res = await api.get("/w/directory/list", { all: true });
    const { list, count } = res;
    if (list) {
      const len = Math.ceil(count / 25);
      for (let i = 0; i < len; i++) {
        const item = list.splice(0, 25);
        this.list.push(item);
      }
      this.list = this.list.reverse();
    }
  },
};
</script>
<style lang="scss" scoped>
#brand-exhibitor {
  padding: 25px 360px;
  .swiper-slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .coop-item {
      width: 186px;
      height: 80px;
      text-align: center;
      margin: 0 20px 30px;
      position: relative;
      img {
        width: 160px;
        max-height: 80px;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  /deep/.swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: "";
  }
  /deep/.swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: "";
  }
}
</style>