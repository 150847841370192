export const apiUrl = 'https://zhongsheyun.com/api'
export const captchaAppId = '2049652090'
// export const apiUrl = 'http://api.dev.zhongsheyun.com/'

// 查看展会信息
// get("/w/mice", params)

// 行业资讯 新闻
// get("/w/article/list", params)
// 参数：page = 0 & pageSize=10 & categoryId=3wtz5pHSHI8reUCBFUGpAk & by=category
// 同期活动  type = 1 会议论坛
// get("/w/mice/list", params)
// 参数：type = 2 & parentId=5iDwTj4QTjH3UU9hi6JI9S
// 品牌展商
// get("/w/directory/list", params)
// 参数：miceId = 5iDwTj4QTjH3UU9hi6JI9S & all=true
// 合作媒体
// get("/w/friends/list", params)


// 行业资讯
// /w/article/list?page=0&pageSize=10&categoryId=3wtz5pHSHI8reUCBFUGpAk&by=category

// 新闻
// /api/w/news/list?page=0&pageSize=10&miceId=5iDwTj4QTjH3UU9hi6JI9S

// 媒体简报
// /w/article/list?page=0&pageSize=10&categoryId=59B7NYvPZCQwi59qATI5XC&by=category