import { render, staticRenderFns } from "./exhibition-section.vue?vue&type=template&id=3e2a851d&scoped=true&"
import script from "./exhibition-section.vue?vue&type=script&lang=js&"
export * from "./exhibition-section.vue?vue&type=script&lang=js&"
import style0 from "./exhibition-section.vue?vue&type=style&index=0&id=3e2a851d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e2a851d",
  null
  
)

export default component.exports