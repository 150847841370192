<template>
  <div id="concurrent-activities">
    <TitleLine>
      <template v-slot:tOne> 同期活动 </template>
      <template v-slot:tTow> Concurrent Activities </template>
    </TitleLine>
    <swiper :options="swiperOption">
      <template v-for="item in list">
        <swiper-slide v-if="item.image" :key="item.list">
          <div class="swiper-box">
            <img :src="item.image" :alt="item.about" />
            <div class="box-info">
              <h1>{{ item.miceName }}</h1>
              <div class="time">{{ (item.start_time * 1000) | date() }}</div>
              <div class="line"></div>
              <div>{{ item.about }}</div>
            </div>
          </div>
        </swiper-slide>
      </template>
      <div class="swiper-button-prev" slot="button-prev">
        <img
          src="../../assets/img/arr_right_3.png"
          style="transform: rotate(180deg)"
        />
      </div>
      <div class="swiper-button-next" slot="button-next">
        <img src="../../assets/img/arr_right_3.png" />
      </div>
    </swiper>
  </div>
</template>

<script>
import * as api from "../../core/api";
import TitleLine from "@/components/title-line.vue";
export default {
  name: "brand-exhibitor",
  components: {
    TitleLine,
  },
  data() {
    return {
      list: [],
      swiperOption: {
        slidesPerView: 7,
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置轮播可循环
        loop: true,
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 5秒切换一次
        },
        centeredSlides: true,
        watchSlidesProgress: true,
        on: {
          setTranslate: function () {
            let slides = this.slides;
            for (let i = 0; i < slides.length; i++) {
              let slide = slides.eq(i);
              let progress = slides[i].progress;
              slide.transform("scale(" + (1 - Math.abs(progress) / 8) + ")");
              slide.css({ "z-index": "" });
              if ((1 - Math.abs(progress) / 8).toFixed(2) == 0.75) {
                slide.css({ "z-index": -1 });
              }
            }
          },
          setTransition: function (transition) {
            for (let i = 0; i < this.slides.length; i++) {
              let slide = this.slides.eq(i);
              slide.transition(transition);
            }
          },
        },
      },
    };
  },
  async created() {
    // 同期活动
    const res = await api.get("/w/mice/list");
    const { list } = res;
    if (list) {
      this.list = list;
    }
  },
};
</script>
<style lang="scss" scoped>
#concurrent-activities {
  background: #e8e8e8;
  padding: 25px 360px;
  .swiper-box {
    width: 240px;
    height: 400px;
    background: #fff;
    box-shadow: 0 0 8px #454040;
    margin: 10px 0;
    background: #e8e8e8;
    color: #333333;
    img {
      width: 100%;
      max-height: 158px;
    }
    .box-info {
      padding: 20px;
      h1 {
        font-size: 16px;
        color: #333;
      }
      .time {
        color: #348942;
        line-height: 35px;
      }
      .line {
        width: 36px;
        height: 5px;
        margin: 10px 0;
        background: #3d3d3c;
      }
      color: #8f8f8f;
      font-size: 11px;
    }
  }
  /deep/.swiper-slide-active {
    z-index: 100;
  }
  /deep/.swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: "";
  }
  /deep/.swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: "";
  }
}
</style>

