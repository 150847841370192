<template>
  <div id="index">
    <Banner />
    <MsgScroll />
    <About />
    <NewsCenter />
    <ExhibitionSection />
    <ConcurrentActivities />
    <BrandExhibitor />
    <!-- 合作媒体 -->
    <CooperativeMedia />
    <Link />
  </div>
</template>

<script>
import Banner from "@/components/index/banner.vue";
import MsgScroll from "@/components/index/msg-scroll.vue";
import About from "@/components/index/about.vue";
import NewsCenter from "@/components/index/news-center.vue";
import ExhibitionSection from "@/components/index/exhibition-section.vue";
import ConcurrentActivities from "@/components/index/concurrent-activities.vue";
import BrandExhibitor from "@/components/index/brand-exhibitor.vue";
import CooperativeMedia from "@/components/index/cooperative-media.vue";
import Link from "@/components/index/link.vue";

export default {
  name: "page-index",
  components: {
    Banner,
    MsgScroll,
    About,
    NewsCenter,
    ExhibitionSection,
    ConcurrentActivities,
    BrandExhibitor,
    CooperativeMedia,
    Link,
  },
};
</script>

<style lang="scss" scoped>
#index {
}
</style>